export const systemMemberCardStyles = [
  'classic',
  'peaceful',
  'lively',
  'nature',
  'fresh',
  'simple',
]

export const systemMemberCardTemplate = [
  { name: 'classic', id: '@system-classic', Creator: { name: '系統' }, isSystem: true, displayName: 'Classic' },
  { name: 'peaceful', id: '@system-peaceful', Creator: { name: '系統' }, isSystem: true, displayName: 'Peaceful' },
  { name: 'lively', id: '@system-lively', Creator: { name: '系統' }, isSystem: true, displayName: 'Lively' },
  { name: 'nature', id: '@system-nature', Creator: { name: '系統' }, isSystem: true, displayName: 'Nature' },
  { name: 'fresh', id: '@system-fresh', Creator: { name: '系統' }, isSystem: true, displayName: 'Fresh' },
  { name: 'simple', id: '@system-simple', Creator: { name: '系統' }, isSystem: true, displayName: 'Simple' },
]
