import { admin2 } from '@/api/instance'

export const CreateMemberCenterColorTheme = async ({
  shopId,
  name,
  primaryColor,
  secondaryColor,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/customColorStyle`,
    data: {
      name,
      primaryColor,
      secondaryColor,
    },
  })
}

export const GetMemberCenterColorTheme = async ({
  shopId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customColorStyle`,
    params: {
      start,
      limit,
    },
  })
}

export const GetMemberCenterColorThemeCount = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customColorStyle/count`,
  })
}

export const FindMemberCenterColorTheme = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/customColorStyle/${id}`,
  })
}

export const UpdateMemberCenterColorTheme = async ({
  shopId,
  id,
  name,
  primaryColor,
  secondaryColor,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/customColorStyle/${id}`,
    data: {
      name,
      primaryColor,
      secondaryColor,
    },
  })
}

export const DeleteMemberCenterColorTheme = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/customColorStyle/${id}`,
  })
}
